/* eslint react/destructuring-assignment: 0 */
import React from 'react'
import styled from '@emotion/styled'
import { Global, css } from '@emotion/core'
import { SEO, Wrapper, Container, Row, Col, SectionHeader, Listing, Navigation, Footer } from '../components'
import { LocaleContext } from '../components/Layout'
import Img from "gatsby-image"

const LocaleSwitcherStyle = theme => css`
  [data-name='locale-switcher'] {
    color: ${theme.colors.greyBlue};
    a {
      color: white;
    }
  }
`
const Privacy = ({ location, pageContext: { locale } }) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]
  return (
    <>
      <Global styles={LocaleSwitcherStyle} />
      <SEO 
        title={`Privacy Policy | ${i18n.defaultTitle}`}
        desc= "Privacy policy"
        pathname={location.pathname}
        locale={locale} 
      />
        <Navigation locale={locale} ></Navigation>
        <Wrapper background="#f5f5f5">
            <Container>
            <Row>
                <Col>
                    <p className="tagline">About</p>
                    <h1 className="position">Imprint</h1>
                </Col>
            </Row>
            </Container>
        </Wrapper>
        <Wrapper>
            <Container>
            <Row>
                <Col><h2>Dceed Bilişim Teknolojileri A.Ş.</h2>
                <p><b>Office Address:</b><br/>Ünalan Mh. Libadiye Cd. No:82 <br/>Emaar Square Sitesi E blok Kat : 291 <br/>Üsküdar, İstanbul<br/></p>
                <p><b>Trade Registration Number:</b> 1261359<br/>
                <b>Mersis NO:</b> 272097036300001</p>
                <p><a href="mailto:contact@dceed.com">contact@dceed.com</a></p>
                <h3>Disclaimer</h3>
                <p>Your access and use of this website constitute your agreement with our terms and conditions.</p>
                <p>Our website created with the utmost care and consideration. However, the author reserves the right not to be responsible for the correctness and completeness of the information.</p>
                <h3>Liability for Links</h3>
                <p>Our website contains links to external third-party websites. We can not assume any liability for the content or privacy of these websites. We encourage you to read the terms and privacy statements of these websites.</p>
                <h3>Copyright</h3>
                <p>The content and works on these pages created by the site operators are subject to  copyright law.</p>
                <p>Any reproduction, exploitation, processing, distribution and alteration of this website or any of its content prohibited unless prior written consent of the respective author or creator.</p>
                </Col>
            </Row>
            </Container>
        </Wrapper>
    <Footer locale={locale} />
    </>
  )
}
export default Privacy